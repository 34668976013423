import React, { useState } from "react";
import * as Styled from "./contactUsStyles";
import Map from "./map";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import ContentfulAssets from "../../hooks/useContentfulAssets";
import { Col, Row } from "react-bootstrap";
import { postFormData } from "../../constants/fetchApi";
import { addTrailingSlash, isExternalUrlhref } from "../../utils";

const ContactUsComponent = (sectionData) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMesssage] = useState(false);

  const UserData = {
    name: name,
    email: email,
    message: message,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      name?.trim() !== "" &&
      email?.trim() !== "" &&
      message?.trim() !== "" &&
      isEmail(email) &&
      hasNoSpecialCharAtStart(name)
    ) {
      setErrorMesssage(false);

      postFormData(UserData)
        .then((res) => {
          if (res.status === 200) {
            window.location.assign(
              isExternalUrlhref("/thank-you/")
                ? "/thank-you/"
                : addTrailingSlash(
                    process.env.GATSBY_DOMAIN_URL + "/thank-you/"
                  )
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setName("");
      setEmail("");
      setMessage("");
    }else{
          if (!hasNoSpecialCharAtStart(name) ) {
            setErrorMesssage("Name should not start with number or special character.");
          }
          if (!isEmail(email) ) {
            setErrorMesssage("Please enter a valid email address.");
          }
    }
  };

  const isEmail = (val) => {
    let regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    } else {
      return true;
    }
  };

  const hasNoSpecialCharAtStart = (val) => {
    var hasNumber = /^(?![^\p{L}\p{M}])/u;
    return hasNumber.test(val);
  };

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = ContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.Image
              src={asset?.node?.file?.url}
              alt={asset?.node?.title}
            />
          );
        }
      },

      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.AddressContainer>
          <span style={{ wordBreak: "break-word" }}>{children}</span>
        </Styled.AddressContainer>
      ),
    },
    renderText: (text) =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <Styled.MainDiv>
      <Styled.HeaderMobile>
        {sectionData?.sectionData?.title}
      </Styled.HeaderMobile>
      <Styled.Container>
        <Styled.FormContainer>
          <Styled.Header>{sectionData?.sectionData?.title}</Styled.Header>
          <Styled.Title>{sectionData?.sectionData?.header}</Styled.Title>
          {sectionData?.sectionData?.list?.map((item, index) => {
            return (
              <Styled.IconAndAddressContainer key={index}>
                {documentToReactComponents(
                  JSON.parse(item?.contentDetails?.raw),
                  optionsMainStyle
                )}
              </Styled.IconAndAddressContainer>
            );
          })}

          <form onSubmit={handleSubmit.bind(this)}>
            <Row>
              <Col lg="5" md="12" sm="12">
                <Styled.Textbox
                  value={name}
                  type="text"
                  required
                  placeholder="Name"
                  onChange={(e) => {
                    setName(e?.target?.value);
                    if(e?.target?.value?.trim().length===0){
                      setErrorMesssage("Name should not be empty.");
                    }else{
                      setErrorMesssage(false);
                      if (!hasNoSpecialCharAtStart(e?.target?.value) ) {
                        setErrorMesssage(
                          "Name should not start with number or special character."
                        );
                      }else{
                        setErrorMesssage(false);
                      }
                    }
                  }}
                  onBlur={(e) => {
                    setName(e?.target?.value?.trim());
                  }}
                ></Styled.Textbox>
              </Col>
              <Col lg="5" md="12" sm="12">
                <Styled.Textbox
                  value={email}
                  type="email"
                  required
                  placeholder="Email Address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if(e?.target?.value?.trim().length===0){
                      setErrorMesssage("Email address should not be empty.");
                    }else{
                      setErrorMesssage(false);
                      if (!isEmail(e?.target?.value) ) {
                        setErrorMesssage("Please enter a valid email address.");
                      }else{
                        setErrorMesssage(false);
                      }
                    } 
                  }}
                  onBlur={(e) => {
                    setEmail(e?.target?.value?.trim());
                  }}
                ></Styled.Textbox>
              </Col>
            </Row>
            <Row>
              <Col lg="10" md="12" sm="6">
                <Styled.TextArea
                  value={message}
                  placeholder="Write your message here"
                  required
                  onChange={(e) => {
                    setMessage(e.target.value);
                    if(e?.target?.value?.trim().length===0){
                      setErrorMesssage("Message should not be empty.");
                    }else{
                      setErrorMesssage(false);
                    }
                  }}
                ></Styled.TextArea>
              </Col>
            </Row>
            {errorMessage && (
              <Styled.ErrorMessage
                className={
                  errorMessage ||
                  !isEmail(email) ||
                  !hasNoSpecialCharAtStart(name)
                    ? "show"
                    : "hide"
                }
              >
                {errorMessage}
              </Styled.ErrorMessage>
            )}
            <Row>
              <Col lg="10" md="12" sm="6">
                <Styled.SendButton
                  type="submit"
                  value="Send"
                  disabled={errorMessage ? true : false}
                ></Styled.SendButton>
              </Col>
            </Row>
          </form>
        </Styled.FormContainer>
        <Styled.MapContainer>
          <Map />
        </Styled.MapContainer>
      </Styled.Container>
    </Styled.MainDiv>
  );
};

export default ContactUsComponent;
